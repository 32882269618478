export default [
  { code: '120300', label: '进出方向', name: 'InOutDirection' },
  { code: '100000', label: '性别', name: 'sex', icon: 'icon-M_AID_Gender' },
  { code: '100100', label: '用户来源', name: 'userFrom' },
  { code: '100150', label: '清晰度', name: 'videoQuality' },
  { code: '100200', label: '报警处理状态', name: 'alarmState' },
  { code: '112900', label: '摄像机朝向', name: 'cameraOrientation' },
  { code: '100250', label: '是否戴口罩',text:'头部特征', name: 'faceMask', icon: 'icon-S_AID_Smile' },
  { code: '100300', label: '任务状态', name: 'taskState' },
  { code: '100350', label: '是否关联用户', name: 'bindUser' },
  { code: '100400', label: '抓拍状态', name: 'captureStatus' },
  { code: '100450', label: '抓拍类型', name: 'captureType' },
  { code: '100500', label: '设备状态', name: 'deviceState' },
  { code: '100600', label: '相机类型', name: 'cameraType' },
  { code: '100700', label: '用户类型', name: 'userType' },
  { code: '100800', label: '年龄段', name: 'generation', icon: 'icon-S_AID_Age' },
  { code: '118600', label: 'VID人员属性', name: 'personnelAttr' },
  { code: '118700', label: 'VID行为属性', name: 'behaviorAttr' },
  { code: '118400', label: '人体年龄', name: 'bodyAge' },
  { code: '100900', label: '库类型', name: 'libraryType' },
  { code: '101100', label: '人脸特征', name: 'faceFeature' },
  { code: '101300', label: '证件类型', name: 'credentialType' },
  { code: '101400', label: '存储方式', name: 'storageType' },
  { code: '101600', label: '报警规则', name: 'alarmRule' },
  { code: '101700', label: '资源类型', name: 'resourceType' },
  { code: '101800', label: '安装方式', name: 'installMethod' },
  { code: '102200', label: '用户等级', name: 'userLevel' },
  { code: '102600', label: '下身服饰', name: 'lowerBodyClothing', icon: 'icon-S_AID_PantsTrousers' },
  { code: '102800', label: '部门行政级别', name: 'departmentLevel' },
  { code: '103000', label: '部门类型', name: 'departmentType' },
  { code: '103400', label: '设备类型', name: 'deviceType' },
  { code: '119500', label: '设备型号', name: 'cameraModel' },
  { code: '106900', label: '民族', name: 'nation' },
  { code: '119400', label: '生产厂家', name: 'cameraBrand' },
  { code: '109400', label: '传感器类型', name: 'sensorType' },
  { code: '110400', label: '分辨率', name: 'resolution' },
  { code: '111400', label: '夜视与曝光', name: 'nightVision' },
  { code: '111500', label: '显示位置', name: 'displayPosition' },
  { code: '111800', label: '开关', name: 'switch' },
  { code: '111900', label: '角色类型', name: 'roleType' },
  { code: '112000', label: '头部特征', name: 'head', icon: 'icon-S_AID_Smile' },
  { code: '112100', label: '上身纹理', name: 'upperTexture', icon: 'icon-S_AID_Texture' },
  { code: '120400', label: '时间标签', name: 'timeTag' },
  { code: '112200', label: '下身类别', name: 'lowerTexture', icon: 'icon-L_Person_PantsShorts' },
  { code: '120410', label: '高峰时段', name: 'peakHours' },
  { code: '112300', label: '随身物品', name: 'goods', icon: 'icon-S_AID_Bag' },
  { code: '112400', label: '上身颜色', name: 'upperColor', icon: 'icon-L_Person_ClothesSolid' },
  { code: '112500', label: '下身颜色', name: 'lowerColor', icon: 'icon-L_Person_PantsTrousers' },
  { code: '112600', label: '是否汉族', name: 'hanNationality' },
  { code: '112700', label: '是否面部遮挡', name: 'facialMask' },
  { code: '112800', label: '是否戴眼镜', name: 'eyeGlass', icon: 'icon-L_Person_Glasses' },
  { code: '119010', label: '是否戴墨镜', name: 'sunglasses', icon: 'icon-L_Person_SunGlasses' },
  { code: '119000', label: '是否戴帽子或头盔',text:'头部特征', name: 'hat', icon: 'icon-S_AID_Smile' },
  { code: '113500', label: '小区类型', name: 'villageType' },
  { code: '113700', label: '楼栋类型', name: 'buildingType' },
  { code: '119100', label: '大数据场所类型', name: 'bigDatePlaceType' },
  { code: '102400', label: '设备安装位置场所类型', name: 'placeType' },
  { code: '114800', label: '文化程度', name: 'degreeEducation' },
  { code: '115000', label: '婚姻状况', name: 'marital' },
  { code: '115300', label: '关爱人员类型', name: 'caringStaffType' },
  { code: '115400', label: '户籍类型', name: 'householdRegisterType' },
  { code: '115500', label: '居住状态', name: 'liveState' },
  { code: '115700', label: '门禁报警类型', name: 'accessAlarmType' },
  { code: '115900', label: '数据来源', name: 'dataSources' },
  { code: '114500', label: '客户类型', name: 'peopleRelation' },
  { code: '116700', label: '颜色深浅', name: 'colorShade' },
  { code: '116720', label: '有无车牌', name: 'plate' },
  { code: '116760', label: '车轮印花纹', name: 'vehicleWheel' },
  { code: '116800', label: '车前部物品', name: 'vehicleFront' },
  { code: '116900', label: '车后部物品', name: 'vehicleRear' },
  { code: '117050', label: '小区进出类型', name: 'villageEntryExitType' },
  { code: '117100', label: '机动车品牌', name: 'vehicleBrands', icon: 'icon-S_Bar_Sign' },
  { code: '118000', label: '行政区划', name: 'administration'},
  { code: '119050', label: 'aid标签行为属性', name: 'aidBehaviorCode' },
  { code: '119200', label: '机动车标志物', name: 'vehicleMarker' },
  { code: '119220', label: '特殊机动车', name: 'specialVehicle' },
  { code: '119240', label: '非机动车机动车角度', name: 'nonMotorVehicleAngle' },
  { code: '119260', label: '非机动车包款式', name: 'nonMotorVehicleType' },
  { code: '119280', label: '非机动车车型', name: 'nonMotorVehicleModel', icon: 'icon-S_Photo_Type' },
  { code: '119300', label: '上身纹理', name: 'upperColorType', icon: 'icon-S_AID_Texture' },
  { code: '117500', label: '机动车类型', name: 'vehicleType', icon: 'icon-S_Photo_Type' },
  { code: '117750', label: '车牌颜色', name: 'plateColor', icon: 'icon-S_Bar_Brand' },
  { code: '119320', label: '车身颜色', name: 'vehicleColor', icon: 'icon-S_Photo_Theme' },
  { code: '101500', label: '布控任务类型', name: 'taskType' },
  { code: '117800', label: '开门方式', name: 'openType' },
  { code: '118200', label: '人物标签', name: 'c' },
  { code: '117900', label: '门禁卡类型', name: 'accessType' },
  { code: '118800', label: '车牌种类', name: 'plateType' },
  { code: '103500', label: '门禁机类型', name: 'accessMachineType' },
  { code: '120110', label: '步态', name: 'gait' },
  { code: '120120', label: '胖瘦', name: 'fatAndThin' },
  { code: '120130', label: '高矮', name: 'height' },
  { code: '120140', label: '场所特征', name: 'placeFeature' },
  { code: '120260', label: '行业', name: 'industry' },
  { code: '120650', label: '建设单位行业', name: 'constructionUnitIndustry' },
  { code: '120500', label: '身份', name: 'identity' },
  { code: '120600', label: 'aid人员行为', name: 'aidBehavior' },
  { code: '120700', label: '常出入场所', name: 'appearPlace'},
  { code: '120890', label: '线索类型', name: 'massType'}
  // { code: '120700', label: '常出入场所', name: 'appearPlace'}
];
