import alarmOperationType from "./alarmOperationType";
import cameraOrientation from "./cameraOrientation";
import platform from "./platform";
import * as time from "./time";
import theme from "./theme";
import userGrade from "./userGrade";
import * as vehicle from "./vehicle";
import videoScreen from "./videoScreen";
import * as device from "./device";
import location from "./location";
import common from "./common";
import baselibOptions from "./baselibOptions";
import systemModule from "./systemModule";
import typeCode from "./typeCode";
import bodyColor from "./bodyColor";
import placeTypeWithIcon from "./placeTypeWithIcon";
import locationType from "./locationType";
import { orderBy } from "lodash";
import appLog from './appLog';
import * as monitorLabel from "./monitorLabel";

const map = {
  appLog,
  platform,
  alarmOperationType,
  cameraOrientation,
  ...time,
  theme,
  userGrade,
  ...vehicle,
  videoScreen,
  ...device,
  location,
  common,
  baselibOptions,
  systemModule,
  placeTypeWithIcon,
  locationType,
  ...bodyColor,
  ...monitorLabel
};

function getLabel(name, code, getText=false) {
  let lable;
  try {
    let temDict = getDict(name);
    if (Array.isArray(temDict)) {
      let item = temDict.find(v => {
        if(!v.value){
          return false
        }
        if (Array.isArray(v.value)) {
          return v.value.findIndex(c => c == code) > -1;
        } else {
          let arr = String(v.value).split(",");
          return arr.findIndex(v => v == code) > -1;
        }
      });
      if(getText) {
        lable = item ? (item.text || item.label) : null;
      } else {
        lable = item ? item.label : null;
      }
    } else {
      lable = temDict.label;
    }
  } catch (e) {
    console.error(e);
    lable = null;
  }
  return lable;
}

function getDict(name) {
  return map[name];
}

function append(arr = []) {
  for (let i in typeCode) {
    if (!map[typeCode[i].name]) {
      let list = arr
        .filter(v => v.typeCode == typeCode[i].code)
        .map(v => {
          return {
            value: v.code,
            label: v.name,
            typeCode: v.typeCode,
            typeName: typeCode[i].name
          };
        });
      map[typeCode[i].name] = orderBy(list, ["value"], "asc");
    }
  }
}

const Dict = {
  typeCode,
  getLabel,
  getDict,
  append,
  map
};

export default Dict;
