export default [
  { value: '112901', label: '正东朝向', score: 90 },
  { value: '112903', label: '正南朝向', score: 180 },
  { value: '112902', label: '正西朝向', score: 270 },
  { value: '112904', label: '正北朝向', score: 0 },
  { value: '112905', label: '东南朝向', score: 135 },
  { value: '112906', label: '东北朝向', score: 45 },
  { value: '112907', label: '西南朝向', score: 225 },
  { value: '112908', label: '西北朝向', score: 315 }
];
