export default [
  { code: 103800, text: '登录' },
  { code: 103900, text: '视频监控' },
  { code: 104100, text: '人脸图库' },
  { code: 104200, text: '人体图库' },
  { code: 105600, text: '机动车图库' },
  { code: 105300, text: '告警处理' },
  { code: 106400, text: '事件处理' },
  { code: 107500, text: '人员临控管理' },
  { code: 105700, text: '以图搜人' },
  { code: 105400, text: '以图搜车' },
  { code: 107400, text: '资源下载' },
  { code: 105100, text: '重点人员布控任务' },
  { code: 106100, text: '外来人员布控任务' },
  { code: 106300, text: '专网套件布控任务' },
  { code: 108200, text: '线索管理' },


  { code: 103801, parent: 103800, text: '登录系统' },

  { code: 103999, parent: 103900, text: '进入视频查看界面' },
  { code: 103919, parent: 103900, text: '进入关注设备列表界面' },
  { code: 103901, parent: 103900, text: '查看实时视频' },
  { code: 103902, parent: 103900, text: '查看录像视频' },
  { code: 103920, parent: 103900, text: '关注视频' },
  { code: 103905, parent: 103900, text: '视频截图' },
  { code: 103906, parent: 103900, text: '视频截图标注' },
  { code: 103907, parent: 103900, text: '视频截图下载' },

  { code: 104199, parent: 104100, text: '进入人脸图库界面' },
  { code: 104105, parent: 104100, text: '查看人脸抓拍照片' },
  { code: 104106, parent: 104100, text: '人脸照片以图搜图' },
  { code: 104115, parent: 104100, text: '收藏图片' },
  { code: 104130, parent: 104100, text: '目标检索' },


  { code: 104299, parent: 104200, text: '进入人体图库界面' },
  { code: 104205, parent: 104200, text: '查看人体抓拍照片' },
  { code: 104206, parent: 104200, text: '人体照片以图搜图' },
  { code: 104215, parent: 104200, text: '收藏图片' },
  { code: 104230, parent: 104200, text: '目标检索' },


  { code: 105699, parent: 105600, text: '进入车辆图库界面' },
  { code: 105605, parent: 105600, text: '进入车辆查询界面' },
  { code: 105601, parent: 105600, text: '查看车辆抓拍图片' },
  { code: 105615, parent: 105600, text: '收藏图片' },
  { code: 105630, parent: 105600, text: '车辆图片以图搜图' },
  { code: 105635, parent: 105600, text: '车牌号检索' },
  { code: 105640, parent: 105600, text: '目标检索' },

  { code: 105399, parent: 105300, text: '进入告警列表界面' },
  { code: 105303, parent: 105300, text: '查看告警信息' },
  { code: 105302, parent: 105300, text: '处理告警信息' },
  { code: 105301, parent: 105300, text: '收藏告警' },

  { code: 106499, parent: 106400, text: '进入事件处理界面' },
  { code: 106401, parent: 106400, text: '查看事件处理信息' },
  { code: 106405, parent: 106400, text: '处理事件信息' },


  { code: 105799, parent: 105700, text: '进入以图搜图界面' },
  { code: 105701, parent: 105700, text: '人脸照片以图搜图' },
  { code: 105702, parent: 105700, text: '人体照片以图搜图' },

  { code: 105499, parent: 105400, text: '进入以图搜图界面' },
  { code: 105401, parent: 105400, text: '车辆照片以图搜图' },

  { code: 107401, parent: 107400, text: '下载照片' },
  { code: 107501, parent: 107500, text: '新建任务' },

  { code: 105199, parent: 105100, text: '进入重点人员布控布控任务管理界面' },
  { code: 105130, parent: 105100, text: '查看重点人员布控任务' },

  { code: 106199, parent: 106100, text: '进入外来人员布控布控任务管理界面' },
  { code: 106130, parent: 106100, text: '查看外来人员布控任务' },

  { code: 106399, parent: 106300, text: '进入专网套件布控布控任务管理界面' },
  { code: 106330, parent: 106300, text: '查看专网套件布控任务' },

  { code: 108205, parent: 108200, text: '上传线索' },
  { code: 108201, parent: 108200, text: '查看线索信息' },

];