export default {
  /*上身颜色*/
  upperColorLocal :[
    {value: null, text: "全部", label: ""},
    {value: "112401", text: "白色", label: "#FFFFFF"},
    {value: "112402", text: "灰色", label: "#B5BBC7"},
    {value: "112403", text: "黑色", label: "#000000"},
    {value: "112406", text: "红色", label: "#FF0000"},
    {value: "112410", text: "橙色", label: "#FF8800"},
    {value: "112408", text: "黄色", label: "#FFDD00"},
    {value: "112404", text: "绿色", label: "#00FF33"},
    {value: "112405", text: "蓝色", label: "#0099FF"},
    {value: "112407", text: "紫色", label: "#9900FF"},
    {value: "112409", text: "粉色", label: "#d82b8f"},
    {value: "112411", text: "棕色", label: "#B36D57"},
    {value: "112412", text: "花色", label: "花色"}
  ],
  /*下身颜色*/
  lowerColorLocal: [
    { value: null, label: '全部', text: '全部' },
    { value: '112501', label: '#ffffff', text: '白色' },
    { value: '112502', label: '#B5BBC7', text: '灰色' },
    { value: '112503', label: '#000000', text: '黑色' },
    { value: '112506', label: '#FF0000', text: '红色' },
    { value: '112510', label: '#FF8800', text: '橙色' },
    { value: '112508', label: '#FFDD00', text: '黄色' },
    { value: '112504', label: '#00FF33', text: '绿色' },
    { value: '112505', label: '#0099FF', text: '蓝色' },
    { value: '112507', label: '#9900FF', text: '紫色' },
    { value: '112509', label: '#d82b8f', text: '粉色' },
    { value: '112511', label: '#B36D57', text: '棕色' },
    { value: '112512', label: '花色', text: '花色' }
  ]
}