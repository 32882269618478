export const selectTime = [
  { value: 1, label: '日' },
  { value: 2, label: '周' },
  { value: 3, label: '月' },
];

export const captureTime = [
  { value: 1, label: '24小时' },
  { value: 3, label: '三天' },
  { value: 7, label: '一周' },
  { value: 30, label: '一个月' }
];

export const weeks = [
  { value: '121121', label: '周一' },
  { value: '121122', label: '周二' },
  { value: '121123', label: '周三' },
  { value: '121124', label: '周四' },
  { value: '121125', label: '周五' },
  { value: '121126', label: '周六' },
  { value: '121127', label: '周日' }
]
