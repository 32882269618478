const Community = '/resource/image/placeFile/Community.svg'
const Hotel = '/resource/image/placeFile/Hotel.svg'
const Hospital = '/resource/image/placeFile/Hospital.svg'
const School = '/resource/image/placeFile/School.svg'
const Village = '/resource/image/placeFile/Village.svg'
const Unit = '/resource/image/placeFile/Unit.svg'
const Business = '/resource/image/placeFile/Business.svg'
const InternetCafe = '/resource/image/placeFile/InternetCafe.svg'
const Station = '/resource/image/placeFile/Station.svg'
const Street = '/resource/image/placeFile/Street.svg'
const Shop = '/resource/image/placeFile/Shop.svg'
const Site = '/resource/image/placeFile/Site.svg'
const Entertainment = '/resource/image/placeFile/Entertainment.svg'
const Gas = '/resource/image/placeFile/Gas.svg'
const Hostel = '/resource/image/placeFile/Hostel.svg'
const Other = '/resource/image/placeFile/Other.svg'

export default [
  {value: "119101",url:Community,icon:'L_Place_Community', label: "小区"},
  {value: "119102",url:Hotel,icon:'L_Place_Hotel', label: "酒店"},
  {value: "119103",url:Hospital,icon:'L_Place_Hospital', label: "医院"},
  {value: "119104",url:School,icon:'L_Place_School', label: "学校",isParent:true,parentId:'119104'},
  {value: "119105",url:Village,icon:'L_Place_Village', label: "乡镇农村"},
  {value: "119106",url:Unit,icon:'L_Place_Unit', label: "社会单位"},
  {value: "119107",url:Business,icon:'L_Place_Business', label: "商圈"},
  {value: "119108",url:InternetCafe,icon:'L_Place_InternetCafe', label: "网吧"},
  {value: "119109",url:Station,icon:'L_Place_Station', label: "车站"},
  {value: "119110",url:Street,icon:'L_Place_Street', label: "小街小巷"},
  {value: "119111",url:Shop,icon:'L_Place_Shop', label: "商铺"},
  {value: "119112",url:Site,icon:'L_Place_Site', label: "工地"},
  {value: "119113",url:Entertainment,icon:'L_Place_Entertainment', label: "娱乐场所",isParent:true,parentId:'119113'},
  {value: "119114",url:Gas,icon:'L_Place_Gas', label: "加油站"},
  {value: "119116",url:Hostel,icon:'L_Place_Hostel', label: "旅社"},
  {value: "119117",url:School,icon:'L_Place_School', label: "小学",parentId:'119104'},
  {value: "119118",url:School,icon:'L_Place_School', label: "中学",parentId:'119104'},
  {value: "119119",url:School,icon:'L_Place_School', label: "高校",parentId:'119104'},
  {value: "119120",url:School,icon:'L_Place_School', label: "幼儿园",parentId:'119104'},
  {value: "119121",url:Entertainment,icon:'L_Place_Entertainment', label: "KTV",parentId:'119113'},
  {value: "119199",url:Other,icon:'L_Place_Other', label: "其他"},
];
