export default [
  { value: "102410", label: "小街小巷" },
  { value: "102411", label: "沿街" },
  { value: "102412", label: "小区" },
  { value: "102413", label: "酒店" },
  { value: "102414", label: "旅社" },
  { value: "102415", label: "乡镇农村" },
  { value: "102416", label: "医院" },
  { value: "102417", label: "学校", isParent: true, parentId: "102417" },
  { value: "102418", label: "小学", parentId: "102417" },
  { value: "102419", label: "中学", parentId: "102417" },
  { value: "102420", label: "高校", parentId: "102417" },
  { value: "102421", label: "幼儿园", parentId: "102417" },
  { value: "102422", label: "社会单位" },
  { value: "102423", label: "政府单位" },
  { value: "102424", label: "广场" },
  { value: "102425", label: "商圈" },
  { value: "102426", label: "商铺" },
  { value: "102427", label: "网吧" },
  { value: "102428", label: "娱乐场所" },
  { value: "102429", label: "加油站" },
  { value: "102430", label: "车站", isParent: true, parentId: "102430" },
  { value: "102431", label: "火车站", parentId: "102430" },
  { value: "102432", label: "汽车站", parentId: "102430" },
  { value: "102433", label: "地铁站", parentId: "102430" },
  { value: "102434", label: "机场" },
  { value: "102435", label: "工地" },
  { value: "102436", label: "景区" },
  { value: "102499", label: "其他" }
];
