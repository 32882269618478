export const monitorLabel = {
  '101000': {
    keyPerson: {
      label: '重点人员',
      alarmLabel: '重点人员告警',
      libLabel: '重点人员库',
      libPeople: '重点人员',
    },
    outsider: {
      label: '外来人员',
      alarmLabel: '外来人员告警',
      libLabel: '合规人员库',
      libPeople: '合规人员',
    }
  },
  '102000': {
    keyPerson: {
      label: '黑名单',      
      alarmLabel: '黑名单告警',
      libLabel: '黑名单库',
      libPeople: '黑名单',
    },
    outsider: {
      label: '白名单',      
      alarmLabel: '白名单告警',
      libLabel: '白名单库',
      libPeople: '白名单',
    }
  }
}