export const vehicleColor = [
  { value: null, label: '全部', text: '全部' },
  { value: 119321, label: '#ffffff', text: '白色' },
  { value: 119323, label: '#000000', text: '黑色' },
  { value: 119322, label: '#B5BBC7', text: '灰色' },
  { value: 119325, label: '#0099FF', text: '蓝色' },
  { value: 119328, label: '#FFDD00', text: '黄色' },
  { value: 119330, label: '#FF8800', text: '橙色' },
  { value: 119331, label: '#B36D57', text: '棕色' },
  { value: 119324, label: '#00FF33', text: '绿色' },
  { value: 119327, label: '#9900FF', text: '紫色' },
  { value: 119329, label: '#d82b8f', text: '粉色' },
  { value: 119326, label: '#FF0000', text: '红色' },
  { value: 119333, label: '#c0c0c0', text: '银色' },
  // { value: 117713, label: '其他', text: '其他' }
];

export const nonVehicleColor = [
  { value: null, label: '全部', text: '全部' },
  { value: 119321, label: '#ffffff', text: '白色' },
  { value: 119323, label: '#000000', text: '黑色' },
  { value: 119322, label: '#B5BBC7', text: '灰色' },
  { value: 119325, label: '#0099FF', text: '蓝色' },
  { value: 119328, label: '#FFDD00', text: '黄色' },
  { value: 119330, label: '#FF8800', text: '橙色' },
  { value: 119331, label: '#B36D57', text: '棕色' },
  { value: 119324, label: '#00FF33', text: '绿色' },
  { value: 119327, label: '#9900FF', text: '紫色' },
  { value: 119329, label: '#d82b8f', text: '粉色' },
  { value: 119326, label: '#FF0000', text: '红色' },
  { value: 119332, label: '#c0c0c0', text: '花色' },
  // { value: 117713, label: '其他', text: '其他' }
];

export const plateColor = [
  { value: null, text: '全部' },
  { value: 117752, label: 1, text: '白色' },
  { value: 117751, label: 2, text: '黑色' },
  { value: 117753, label: 3, text: '黄色' },
  { value: 117754, label: 4, text: '蓝色' },
  { value: 117755, label: 5, text: '绿色' },
  { value: 117756, label: 6, text: '黄绿色' },
  { value: 117757, text: '其他' }
];

export const vehicleClasses = [
  {
    value: null,
    label: '全部',
    ids: null
  },
  {
    value: 'b',
    label: '轿车',
    ids: [117669]
  },
  {
    value: 'c',
    label: '面包车',
    ids: [117670]
  },
  {
    value: 'd',
    label: '越野车/SUV',
    ids: [117674]
  },
  {
    value: 'e',
    label: '皮卡',
    ids: [117671]
  },
  {
    value: 'f',
    label: '商务车/MPV',
    ids: [117672]
  },
  {
    value: 'g',
    label: '三轮车',
    ids: [117667, 117594]
  },
  {
    value: 'h',
    label: '摩托车',
    ids: [
      117585,
      117586,
      117587,
      117588,
      117589,
      117590,
      117591,
      117592,
      117593
    ]
  },
  {
    value: 'i',
    label: '货车',
    ids: [
      117525,
      117526,
      117527,
      117528,
      117529,
      117530,
      117531,
      117532,
      117533,
      117534,
      117535,
      117536,
      117537,
      117538,
      117539,
      117540,
      117541,
      117542,
      117543,
      117544,
      117545,
      117546,
      117547,
      117548,
      117549,
      117550,
      117551,
      117552,
      117553,
      117554,
      117555,
      117556,
      117557,
      117558,
      117559,
      117560,
      117561,
      117562,
      117563,
      117564,
      117565,
      117566,
      117567,
      117675,
      117676
    ]
  },
  {
    value: 'j',
    label: '客车',
    ids: [
      117501,
      117502,
      117503,
      117504,
      117505,
      117506,
      117507,
      117508,
      117509,
      117510,
      117511,
      117512,
      117513,
      117514,
      117515,
      117516,
      117517,
      117518,
      117519,
      117520,
      117521,
      117522,
      117523,
      117524
    ]
  },
  {
    value: 'k',
    label: '公交车',
    ids: [117668]
  },
  {
    value: 'l',
    label: '校车',
    ids: [117673]
  },
  {
    value: 'm',
    label: '电车',
    ids: [117583, 117584]
  },
  {
    value: 'n',
    label: '拖拉机',
    ids: [117595, 117596, 117597, 117598, 117599]
  },
  {
    value: 'o',
    label: '牵引车',
    ids: [
      117568,
      117569,
      117570,
      117571,
      117572,
      117573,
      117574,
      117575,
      117576
    ]
  },
  {
    value: 'p',
    label: '专项作业车',
    ids: [117577, 117578, 117579, 117580, 117581, 117582]
  },
  {
    value: 'q',
    label: '轮式机械',
    ids: [117600, 117601, 117602]
  },
  {
    value: 'r',
    label: '全挂车',
    ids: [
      117603,
      117604,
      117605,
      117606,
      117607,
      117608,
      117609,
      117610,
      117611,
      117612,
      117613,
      117614,
      117615,
      117616,
      117617,
      117618,
      117619,
      117620,
      117621,
      117622,
      117623,
      117624,
      117625,
      117626,
      117627,
      117628,
      117629,
      117630,
      117631
    ]
  },
  {
    value: 's',
    label: '半挂车',
    ids: [
      117632,
      117633,
      117634,
      117635,
      117636,
      117637,
      117638,
      117639,
      117640,
      117641,
      117642,
      117643,
      117644,
      117645,
      117646,
      117647,
      117648,
      117649,
      117650,
      117651,
      117652,
      117653,
      117654,
      117655,
      117656,
      117657,
      117658,
      117659,
      117660,
      117661,
      117662,
      117663,
      117664,
      117665
    ]
  },
  {
    value: 't',
    label: '其他',
    ids: [117666]
  }
];